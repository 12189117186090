<template>
    <div class="apply">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <div class="container text-center text-light header-container">
                <h1 class="display-4">Werde Teil des Teams</h1>
                <p class="lead w-75 mx-auto">
                    Mit MC ONE stehen wir noch ganz am Anfang. Deshalb suchen wir aufgeweckte und kreative neue
                    Mitglieder, die Spaß am Aufbau und der Entwicklung eines einzigartigen Netzwerks haben!
                </p>
            </div>
        </div>


        <section class="mt-md-3">
            <div class="container text-center text-md-left">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <h3>Deine Bewerbung</h3>
                        <p class="text-muted">
                            Warum auf MC ONE Teammitglied sein?
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <p>
                            Wir haben die Vision eines großen Netzwerks auch über Minecraft hinaus.
                            Deshalb arbeiten wir schon mehrere Jahre an der Perfektion des Netzwerks und allen dazugehörigen Plattformen wie z.B. dieser Website.
                            Du findest bei uns ein kreatives Team mit neuen Ideen und hast die möglichkeit uneingeschrängt aufzusteigen.
                            Wie viele andere Communities können auch wir es uns zur Zeit nicht leisten unseren Teammitgliedern ein Gehalt zu zahlen.
                            Du kannst uns glauben, echtes Geld verdient man mit anderen Dingen.
                            Aber darum geht es uns bei diesem Server auch nicht. Wir wollen kreative neue Spielmodi entwickeln und brauchen dafür kreative neue Teammitglieder.<br>
                            <br>
                            Falls du also Interesse an einem Netzwerk hast, das am liebsten so viel wie möglich selbst entwickeln möchte, dann bewirb dich jetzt!
                        </p>
                    </div>
                    <div class="col-md-4">
                        <a class="btn btn-secondary m-1" href="ts3server://mcone.eu?port=9988">
                            <fai :icon="['fas', 'headset']"/>
                            TeamSpeak Server besuchen
                        </a>
                        <a class="btn btn-outline-secondary m-1" href="mailto:apply@mcone.eu">
                            <fai :icon="['far', 'envelope']"/>
                            E-Mail schicken
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section v-for="(job, i) in jobs" v-bind:key="i" v-bind:class="{ 'bg-light': i%2 === 0 }">
            <div class="container">
                <div class="row">
                    <div :class="['col-md-8', 'text-center', 'order-12', i%2 === 0 ? 'text-md-left' : 'text-md-right', i%2 === 0 ? 'order-md-1' : '']">
                        <h2>{{ job.name }}</h2>
                        <p v-html="job.description"></p>
                        <b-button v-if="job.name === 'Entwickler' || job.name === 'Builder' || job.name === 'Content'" :variant="job.variant" size="lg" class="btn-switch" target="_blank" :href="`mailto:apply@mcone.eu?subject=${job.name}-Bewerbung`">
                            Per E-Mail bewerben
                        </b-button>
                        <b-button v-else :variant="job.variant" size="lg" class="btn-switch" disabled>
                            Keine Bewerbungsphase
                        </b-button>
                       <!-- <b-button :variant="job.variant" size="lg" class="btn-switch" :to="job.opened ? job.link : ''"
                                  :disabled="!job.opened">
                            {{ job.opened ? 'Mehr Infos' : 'Keine Bewerbungsphase' }}
                        </b-button>-->
                    </div>
                    <div :class="['col-md-4', 'text-center', 'order-1', i%2 === 0 ? 'order-md-12' : '']">
                        <img v-bind:src="job.img" class="rank-logo" :alt="job.name">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                jobs: [
                    {
                        name: 'Entwickler',
                        description: 'Als Java-Entwickler hilfst du uns je nach deinen Fähigkeiten und deiner Zeit im Team als Game-Developer (Spielmodi programmieren), ' +
                            'als System-Developer (Spigot- & Bungee-Server Systeme programmieren) oder als App-Developer (Cloudsystem & Bots programmieren).<br>' +
                            '<br>' +
                            'Als Webentwickler hilfst du uns je nach dem was du lieber machst bei Front-End (Landingpage, Team-Dashboard, ...) Projekten ' +
                            'in <code>HTML</code>, <code>(S)CSS</code>, <code>JS</code> und/oder Back-End Projekten (MCONE API, Cloud API, ...) in <code>PHP</code>. ' +
                            'Du solltest schon wenige Erfahrungen in Javascript oder PHP Frameworks wie Vue.js oder Laravel gesammelt haben oder das Design für eine größere Seite konzipiert haben.<br>' +
                            '<br>' +
                            'Mindestbewerbungsalter: 16 Jahre',
                        img: require('@/assets/img/developer_logo.png'),
                        opened: false,
                        variant: 'info',
                        link: '/apply/developer'
                    },
                    {
                        name: 'Builder',
                        description: 'Als Builder baust oder erweiterst du Maps für unseren Server in verschiedenen Stilen. ' +
                            'Du solltest also Interesse an verschienen Baustilen und etwas Erfahrung mit ihnen haben.<br>' +
                            '<br>' +
                            'Als Builder arbeitest du im Team. Du solltest also konstruktive Kritik annehmen und auch aussprechen können ' +
                            'bzw. flexibel genug sein um alte Ideen evt. ganz zu überdenken. Aber keine Angst, du hast genug Zeit um auch alleine deine Kreativität umsetzen zu können.<br>' +
                            '<br>' +
                            'Mindestbewerbungsalter: 14 Jahre',
                        img: require('@/assets/img/builder_logo.png'),
                        opened: false,
                        variant: 'warning',
                        link: '/apply/builder'
                    },
                    {
                        name: 'Supporter',
                        description: 'Als Supporter bist du in erster Linie Ansprechpartner für alle Spieler und somit ein wichtiges Bindeglied zwischen den Usern und dem Team. ' +
                            'Du hilfst beim beantworten von Support Tickets und Fragen auf dem Minecraft- und Teamspeak-Server.<br>' +
                            '<br>' +
                            'Nach etwas Zeit im Team hast du die Chance zum Moderator aufzusteigen und dich auch um Bans, Spieler Reports und Events zu kümmern.<br>' +
                            '<br>' +
                            'Mindestbewerbungsalter: 16 Jahre (Ausnahmen möglich)',
                        img: require('@/assets/img/supporter_logo.png'),
                        opened: false,
                        variant: 'success',
                        link: '/apply/supporter'
                    }/*,
                    {
                        name: 'Designer',
                        description: 'Als Designer hilfst du uns beim Entwerfen von Grafiken und Designs auf allen Plattformen. ' +
                            'Das beinhaltet sowohl das erstellen von Werbebannern für unseren Teamspeak Server oder Soziale Netzwerke ' +
                            'als auch das designen neuer Marken-Logos, Icons oder Hintergründe für unsere Homepage.<br>' +
                            '<br>' +
                            'Aus diesem Grund solltest du dich auf mindestens einem der folgenden Gebiete wohlfühlen: ' +
                            'Bildnachbearbeitung/Bannerdesign (z.B. mit Photoshop), Vektorgrafikdesign (z.B. mit Illustrator), 3D Modelling (z.B. mit Cinema4D), Videoschnitt (z.B. mit Premiere Pro)<br>' +
                            '<br>' +
                            'Mindestbewerbungsalter: 15 Jahre',
                        img: require('@/assets/img/designer_logo.png'),
                        opened: false,
                        variant: 'primary',
                        link: '/apply/designer'
                    }*/,
                    {
                        name: 'Content',
                        description: 'Als Content für <b>Gamedesign</b> entwickelst arbeitest du neue Ideen für alle unsere Plattformen und Spielmodi aus (z.B. Lobby, Discord-Layout, Homepage Funktionen, ...). ' +
                            '<br><br>' +
                            'Als Content für <b>Public Relations</b> arbeitest du an der Planung von Events, Videos und Livestreams und wie der Server für Spieler noch attraktiver wird. ' +
                            'Du erstellst Beiträge für unseren Sozialen Netzwerke und arbeitest in der Kommunikation mit unseren Creators.<br><br>' +
                            'Als Content für <b>Design</b> hilfst du uns beim Entwerfen von Grafiken und Designs auf alle Plattformen oder beim Produzieren unserer Trailer und Supportvideos. ' +
                            'Du solltest dich auf mindestens einem dieser Gebiete auskennen: ' +
                            'Bildnachbearbeitung/Bannerdesign (z.B. mit Photoshop), Vektorgrafikdesign (z.B. mit Adobe XD/Illustrator, Figma), 3D Modelling (z.B. mit Cinema4D), Videoschnitt (z.B. mit Premiere Pro)' +
                            '<br>' +
                            'Mindestbewerbungsalter: 15 Jahre',
                        img: require('@/assets/img/designer_logo.png'),
                        opened: false,
                        variant: 'primary',
                        link: '/apply/content'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/breakpoints";

    .header-wrapper {
        background: linear-gradient(
                        to bottom right,
                        rgba(0, 28, 55, 0.7),
                        rgba(15, 0, 0, 0.6)
        ), url('../assets/img/lobby.png') center/cover no-repeat border-box;
        padding-top: 50px;
    }

    section {
        padding: 40px 0 40px 0;
    }

    .rank-logo {
        height: 300px;
        width: 300px;
    }

    @include media-breakpoint-down(sm) {
        section {
            padding: 20px 0 20px 0;
        }

        .btn-switch {
            display: block;
            margin: 1rem auto 1rem auto;
        }
    }

    @include media-breakpoint-down(md) {
        .rank-logo {
            height: 200px;
            width: 200px;
        }
    }
</style>
